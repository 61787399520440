import React, { useEffect } from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "./theme";
import { GlobalStyles } from "./global";
import TagManager from 'react-gtm-module';

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-00GC2XNMWG' // Replace with your GA4 measurement ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ThemeProvider theme={chosenTheme}>
      <>
        <GlobalStyles />
        <div>
          <Main theme={chosenTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;

// import React, { useEffect } from "react";
// import "./App.css";
// import Main from "./containers/Main";
// import { ThemeProvider } from "styled-components";
// import { chosenTheme } from "./theme";
// import { GlobalStyles } from "./global";

// import ReactGA from "react-ga";
// function App() {
//   useEffect(() => {
//     // Change this below tracking id to your own
//     ReactGA.initialize("G-00GC2XNMWG");
//   });
//   return (
//     <ThemeProvider theme={chosenTheme}>
//       <>
//         <GlobalStyles />
//         <div>
//           <Main theme={chosenTheme} />
//         </div>
//       </>
//     </ThemeProvider>
//   );
// }

// export default App;
